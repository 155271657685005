import React from 'react';
import { ItemEditorProps, ListEditor } from '../../../toolympus/components/primitives/ListEditor';
import { ContentEditorProps } from './types';
import { generateCode } from '../../../toolympus/components/PowerDoc/plugins/common';
import { FormGrid } from '../../../toolympus/components/primitives';
import { FormControlsForFields } from '../../../toolympus/components/schemed';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import styled from '@emotion/styled';
import { ThemeSettings } from '../../../theme';
import { LegacyPerson } from '../useAdminLegacyInfoList';
import { MediaLibPicker, useMediaLibContext } from '../../../toolympus/components/medialib';

const PersonSchema: Schema = {
  first_name: { label: "First name" },
  last_name: { label: "Last name" },
  email: { label: "E-mail" },
  phone: { label: "Phone" },
  is_contact_disclosed: { label: "Contact disclosed", type: FieldType.bool },
}

const PersonWrapper = styled(FormGrid)`
  align-items: end;
  border-left: 2px solid ${ThemeSettings.colors.tableBorder};
  padding-left: 20px;

  & > .medialib-picker {
    & .placeholder {
      width: 150px;
      height: 150px;
    }
    & > img {
      width: 150px;
      height: 150px;
      border-radius: 75px;
      overflow: hidden;
      box-shadow: ${ThemeSettings.sizes.shadow.button} ${ThemeSettings.colors.shadow.button};
      object-position: center center;
      box-shadow: ${ThemeSettings.sizes.shadow.button} ${ThemeSettings.colors.shadow.button};
    }
  }
`;
PersonWrapper.defaultProps = { columns: "1fr 150px", gap: "dense", noMargin: true }

const ListWrapper = styled.div`
`;

const PersonEditor = (props: ItemEditorProps<LegacyPerson>) => {
  const medialib = useMediaLibContext();

  return <PersonWrapper>
    <FormGrid columns="1fr 1fr">
      <FormControlsForFields
        data={props.item}
        onChange={(o,c) => props.update(c)}
        schema={PersonSchema}
        fields={[
          ["first_name"],
          ["last_name"],
          ["email"],
          ["phone"],
          ["is_contact_disclosed"],
        ]}
        />
    </FormGrid>

      <MediaLibPicker
        selectedUrl={props.item.avatar_url}
        selectFile={mf => props.update({ avatar_url: mf ? medialib.getFilepath(mf) : null })}
        closeOnSelect
        
        />
  </PersonWrapper>
}

export const PeopleEditor = (props: ContentEditorProps ) => {
  return (
    <ListEditor
      idField="_id"
      items={props.block.content?.items}
      createDflt={() => ({ _id: generateCode() })}
      update={items => props.update({ content: { ...props.block?.content, items }})}
      itemEditor={PersonEditor}
      wrapperComponent={ListWrapper}        
      />
  );
}

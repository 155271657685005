import { useMemo } from "react";
import { apiFetch } from "../../toolympus/api/core";
import { EditItemProps, useEditItem2, useNewItem } from "../../toolympus/api/useNewItem";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { FieldType, mergeSchema, useSingleSchema } from "../../toolympus/hooks/useSchema";

export interface LegacyInfo {
  _id: number;
  year: string;
  university: string;
  block_type: string;
  is_hidden_from_public?: boolean;
  content: any;
}

export interface LegacyPerson {
  _id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  is_contact_disclosed?: boolean;
  avatar_url?: string | null;
}

export interface LegacyDocument {
  _id: string;
  title?: string;
  url?: string | null;
}


export interface LegacyTransferParams {
  year: string;
  members?: boolean;
  advisors?: boolean;
  documents?: boolean;
}



const ApiPath = "/api/legacy-info/admin";


export const useAdminLegacyInfoList = () => {
  const filters = useEditItem2<Partial<Pick<LegacyInfo, "year" | "university">>>({
    startWith: {
    },
  });

  const urlWithFilters = `${ApiPath}?${Object.entries(filters.item || {}).filter(([k,v]) => !!v).map(([k,v]) => `${k}=${v}`).join("&")}`;

  const data = useLoadedData<LegacyInfo[]>(urlWithFilters, []);
  const { schema } = useSingleSchema(`${ApiPath}/uiconfig`);
  const filtersSchema = useMemo(() => mergeSchema(
    schema,
    { year: { type: FieldType.dictionarySelect, dictionary: "LegacyYears" }},
  ), [schema]);

  const create = useNewItem<Partial<LegacyInfo>, LegacyInfo>(ApiPath, {});

  const edit = useEditItem2<LegacyInfo>({
    getApiPath: item => `${ApiPath}/${item._id}`,
  });

  const remove = (id: number) => apiFetch<{}>(`${ApiPath}/${id}`, "delete").then(() => { data.reload(); });
  
  const transfer = useEditItem2<LegacyTransferParams>({
    save: (item, changes) => {
      return apiFetch<{}>(`/api/legacy-info/transfer`, "post", item)
        .then(x => {
          data.reload();
          return item;
        });
    }
  })

  return {
    ...data,
    schema,
    filtersSchema,
    create: {
      ...create,
      save: () => create.save().then(x => { data.reload(); return x; }),
    },
    edit: {
      ...edit,
      save: () => edit.save().then(x => { data.reload(); return x; })
    },
    remove,
    filters,
    transfer: {
      ...transfer,
      startEditing: () => {
        transfer.startEditing({ year: new Date().getFullYear().toString(), members: false, advisors: false, documents: false });
      }
    },
  }
}

export type LegacyInfoTransferData = EditItemProps<LegacyTransferParams>;
